import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { getVertexAI, getGenerativeModel } from 'firebase/vertexai-preview';

const firebaseConfig = {
  apiKey: 'AIzaSyBPPLl8CV3Yux0m5HyLtp55r3hftWKmGPI',
  authDomain: 'tinyfrontend.firebaseapp.com',
  projectId: 'tinyfrontend',
  storageBucket: 'tinyfrontend.appspot.com',
  messagingSenderId: '484558970092',
  appId: '1:484558970092:web:e69d98e2ce972ce6e159c9',
  measurementId: 'G-YX50NTN73G',
};

const app = firebase.initializeApp(firebaseConfig);

// append utils
app.utils = {
  parsePosts: (res) => {
    const posts = [];
    res.forEach((rs) => {
      posts.push({
        id: rs.id,
        ...rs.data(),
      });
    });
    return posts;
  },
  parsePost: (res) => {
    return {
      id: res.id,
      ...res.data(),
    };
  },
};

app.ai = getGenerativeModel(getVertexAI(app), { model: 'gemini-2.0-flash' });

export default app;
