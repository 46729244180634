import React from 'react';
// utils
import { call, isAvailable } from 'Util/Agent';
import { getContentSlides } from 'Util/Post';
import { getPreviewURL } from './utils';
//
import './styles.scss';

function TemplateQuiz({ post }) {
  return (
    <iframe
      className="border-2 border-gray-100 bg-white w-full h-full"
      width="100%"
      height="100%"
      src={getPreviewURL(post)}
    />
  );
}

export default {
  id: 'tiny-quiz',
  title: 'TinyQuiz',
  icon: 'smartphone',
  color: 'text-blue-500',
  onSave: async ({ post }) => {
    // run post deploy automatically
    if (post.slug && post.status !== 'production') {
      await call(`/build/tiny-quiz/${post.id}`);
    }
  },
  renderToolbar: ({
    post,
    onChange,
    renderTextButton,
    renderColorButton,
    renderTextInput,
    renderDivider,
    renderImageButton,
    renderSelectButton,
  }) => {
    const previewURL = getPreviewURL(post);
    return (
      <>
        {renderTextInput({
          key: 'companies',
          placeholder: 'Apple, Microsoft',
          icon: 'tag',
        })}
        {renderSelectButton({
          key: 'topic',
          icon: 'folder',
          options: [
            { id: '', title: '[Topic]' },
            { id: 'JavaScript', title: 'JavaScript' },
            { id: 'HTML', title: 'HTML' },
            { id: 'CSS', title: 'CSS' },
            { id: 'Frontend Engineering', title: 'Frontend Engineering' },
            { id: 'HTTP', title: 'HTTP' },
            { id: 'Browser', title: 'Browser' },
            { id: 'Web Security', title: 'Web Security' },
          ],
        })}
        {renderSelectButton({
          key: 'frequency',
          icon: 'bar-chart-2',
          options: [
            { id: '', title: '[Frequency]' },
            { id: 'High', title: 'High' },
            { id: 'Medium', title: 'Medium' },
            { id: 'Low', title: 'Low' },
          ],
        })}
        {renderDivider()}
        {renderTextInput({
          key: 'slug',
          placeholder: '0105',
          icon: 'hash',
        })}
        {renderImageButton({
          url: `https://quickchart.io/qr?text=${encodeURIComponent(
            previewURL
          )}&margin=2&size=200`,
          onClick: () => {
            window.open(previewURL);
          },
        })}
      </>
    );
  },
  renderTemplate: (props) => {
    return <TemplateQuiz {...props} />;
  },
};
